.cards {
  display: flex;
  gap: 1.5rem;
  justify-content: center;
}

ul {
  list-style: none;
}

.card-container {
  background: transparent linear-gradient(180deg, #0f0f0f 0%, #393939 100%) 0% 0% no-repeat padding-box;
  border: 1px solid #ffffff1a;
  border-radius: 10px;
  color: #fff;
  font-family: Arial, sans-serif;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  max-width: 50%;
}

.card-container2 {
  /* box-shadow:
    rgba(255, 153, 0, 0.25) 0px 0px 30px,
    rgba(255, 153, 0, 0.12) 0px 0px 5px,
    rgba(255, 153, 0, 0.12) 0px 0px 1px,
    rgba(255, 153, 0, 0.17) 0px 0px 3px,
    rgba(255, 153, 0, 0.09) 0px 0px 1px; */

  border: 1px solid #FFB200; 
}

.card-container2 h3 {
  color: #FFB200 !important;
  border-bottom: 1px solid #60BA99 !important;

}

.card-container h3 {
  margin-bottom: 20px;
  font-size: 1.8rem;
  color: #ffffff;
  text-transform: capitalize;
  font-weight: 500;
  padding-bottom: 10px;
  border-bottom: 1px solid #F3625B;
}

.card-container ul {
  padding-left: 0;
}

.card-container li {
  display: flex;
  align-items: flex-start;
  margin-bottom: 2rem;
}

.card-container .icon {
  font-size: 0.8rem;
  width: 1.2rem;
  height: 1.2rem;
  margin-right: 0.7rem;
  margin-top: 0.4rem;
}

.card-container label {
  color: #fff;
  font-size: 1.2rem;
  line-height: 1.85rem;
}

.card-container span {
  color: #ffffff80;
  font-size: 1.2rem;
  line-height: 1.85rem;
}

.ways {
  min-height: 80vh;
  margin-bottom: 8rem;
  margin-top: 4rem;
  display: flex;
  align-items: center;
}

.head-sec {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding: 5rem 0 0 0;
  position: relative;
  z-index: 1;
}

.new-hed-sec {
  padding: 0 0 5rem 0 !important;
}

.head-sec h4,
.head-sec p,
.head-sec span {
  color: #fff;
  text-align: left;
}

.get-in-touch .head-sec h4,
.get-in-touch .head-sec p,
.get-in-touch .head-sec span {
  color: #fff !important;
  text-align: center !important;
}

.get-in-touch .head-sec {
  align-items: center;
}

.dim {
  opacity: 0.6;
}

.products {
  padding-bottom: 4rem !important;
}

.details-image {
  position: absolute;
  z-index: 1;
}

.products .img-wrap {
  position: relative;
  height: 240vh;
}

.products .img-wrap img {
  position: absolute;
}

.head-sec h4 {
  font-size: 3rem;
  max-width: 70%;
  text-align: center;
  font-weight: 600;
}

.head-sec p {
  font-size: 1.5rem;
  line-height: 2.3rem;
  margin-top: 1.5rem;
  max-width: 70%;
}

.products .head-sec span {
  opacity: 1;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: #FFB200 !important; 
}

.products .head-sec span,
.products .head-sec p,
.products .head-sec h4 {
  text-align: left;
  color: #1f1f1f;
}

.new-text {
  color: #ffffff !important;
}


.products,
.spend {
  min-height: 100vh;
  background: #f4f4f5 0% 0% no-repeat padding-box;
}

.products2 {
  background: transparent;

}

.img-g4 {
  position: absolute !important;
  z-index: 0;
  width: 90rem !important;
  left: -28%;
  top: 5%;
}

.mb-4 {
  margin-bottom: 4rem;
}

.verify {
  padding-bottom: 8rem !important;
  padding-top: 5rem !important;
  /* background: transparent linear-gradient(180deg, #000000 0%, #221500 100%) 0% 0% no-repeat padding-box; */
  z-index: 10;
  position: relative;
  min-height: 80vh;
}
.verify-less {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  margin: 0 !important;
}

.new-verify {
  padding-bottom: 0rem !important;
}

.industry-verify {
  padding-top: 0rem !important;
}
.verify .cards ul li img {
  /* width: 100%; */
  height: 29.5rem;
}

/* .verify .marquee-container ul li:first-child { */
  /* margin-left: 2rem; */
/* } */

.marquee-mar {
  margin-left: 2.2rem;
}

.ml2 {
  margin-left: 2.5rem;
}

.verify .cards ul li span {
  color: #ffffff;
  font-weight: 600;
  margin-bottom: 1rem;
  display: block;
}

.verify .cards ul {
  display: flex;
  gap: 2rem;
}

.instant img {
  width: 42rem;
}

.instant {
  padding: 0 5rem 5rem 5rem!important;
  position: relative;
}

.instant>* {
  position: relative;
  z-index: 5;
}

.instant .head-sec h4 {
  max-width: 75%;
}

.instant .head-sec p {
  max-width: 80%;
}

.instant .flex-between {
  min-height: 82vh;
}

.industry-card {
  background: transparent linear-gradient(180deg, #0f0f0f 0%, #393939 100%) 0% 0% no-repeat padding-box;
  border: 1px solid #ffffff1a;
  border-radius: 10px;
  padding: 1.8rem;
  height: 100%;
  width: 100%;
}

.industry-card:hover {
  background: #FFB200;
  opacity: 1;
}

.industry-card h5 {
  color: #fff;
  font-size: 1.6rem;
  font-weight: 600;
  letter-spacing: 0.4px;
  margin-bottom: 1rem;
}

.industry-card p {
  color: #FFFFFF;
  font-size: 1.3rem;
  line-height: 2rem;
  max-width: 88%;
  opacity: 0.7;
}

.industry.cards {
  flex-wrap: wrap;
}

.industry ul {
  display: grid !important;
  grid-template-columns: auto auto auto;
  gap: 2rem;
}

.industry ul li {
  min-height: 18rem;
}

.get-in-touch {
  background: #000000;
  border-radius: 40px;
  max-width: 90%;
  margin: auto;
  position: relative;
  z-index: 10;
}

.get-in-touch:hover {
  box-shadow:
    rgba(255, 153, 0, 0.25) 0px 0px 120px,
    rgba(255, 153, 0, 0.12) 0px 0px 40px,
    rgba(255, 153, 0, 0.12) 0px 0px 40px,
    rgba(255, 153, 0, 0.17) 0px 0px 32px,
    rgba(255, 153, 0, 0.09) 0px 0px 36px;
}

.in-flex-center {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  gap: 7px;
}

.flex-center {
  justify-content: center;
}

.down-arrow {
  width: 43px;
  margin-top: 0;
  margin-bottom: 6rem;
}

@keyframes moveUpDown {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(20px);
    /* Adjust this value to control how far it moves */
  }

  100% {
    transform: translateY(0);
  }
}

.infinite-move {
  animation: moveUpDown 2s linear infinite;
}


/* footer css */

.footer {
  /* background: transparent linear-gradient(180deg, #000000 0%, #221500 100%) 0% 0% no-repeat padding-box; */
  background-image: url(./assets/footer-background.png);
  background-size: cover;
  width: 100vw;
  height: 80vh;
  padding-top: 15rem !important;
  margin-top: 8rem;
  position: relative;
  z-index: 1;
  color: #fff;
  padding: 5rem;
  display: flex;
  /* justify-content: space-between; */
  align-items: flex-start;
}

.footer-logo img {
  width: 4rem;
  margin-left: 1rem;
  margin-bottom: 1rem;
}

.footer-social,
.footer-address,
.footer-links {
  margin: 0 20px;
}

.footer-social h4,
.footer-address h4,
.footer-section h4 {
  margin-bottom: 10px;
  color: #ffffff;
  font-weight: 600;
}

.mobile-footer {
  display: none;
}

.social-icons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 2rem;
  gap: 0.5rem;
}

.social-icons a {
  margin-right: 10px;
  color: #fff;
  font-size: 18px;
}

.footer-address p {
  max-width: 23rem;
  color: #ffffff80;
  opacity: 0.5;
  line-height: 1.5rem;
}

.footer-links {
  display: flex;
  gap: 4rem;
  padding-right: 4rem;
}

.footer-section {
  margin-right: 40px;
}

.footer-section ul {
  list-style: none;
  padding: 0;
}

.footer-section li {
  margin-top: 1.1rem;
}

.footer-section li {
  color: #ffffff80;
  text-decoration: none;
  opacity: 0.5;
}

.footer-section li:hover {
  opacity: 1;
  color: #ffffff;

}

.footer-section a:hover {
  text-decoration: underline;
}

/* Trusted */
.trusted {
  min-height: 55vh;
  display: flex;
  gap: 3rem;
  align-items: center;
}

.trusted p {
  color: #ffffff;
  font-size: 1.2rem;
  max-width: 20rem;
  margin-right: 4rem;
  opacity: 0.52;
  font-weight: 400;
}

.trusted ul {
  display: flex;
  align-items: center;
  gap: 3rem;
}

.trusted ul li img {
  height: 2.3rem;
}

.nav-item {
  color: #fff;
}

.menu-container {
  position: relative;
  width: 150px;
  height: 150px;
}

.circular-menu {
  position: fixed;
  top: 3rem;
  right: 4rem;
  z-index: 100;
}

.circular-menu .floating-btn {
  position: relative;
  display: block;
  width: 3rem;
  height: 3rem;
  border-radius: 100%;
  color: white;
  text-align: center;
  line-height: 3.9;
  outline: 0;
  background: #FFB200;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circular-menu.active .floating-btn {
  background: none;
}

.circular-menu .floating-btn::after {
  content: "";
  position: absolute;
  border-radius: 100%;
  width: 3rem;
  height: 3rem;
  background: #FFB200;
  opacity: 0.6;
  /* Starting opacity */
  transform: scale(1);
  /* Initial size */
  animation: pulse 2s ease-in-out infinite;
  z-index: -1;
}

.circular-menu.active .floating-btn::after {
  background: none;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    /* Starting size */
    opacity: 0.6;
    /* Starting opacity */
  }

  50% {
    transform: scale(1.2);
    /* Slightly larger size */
    opacity: 0.3;
    /* More transparent at the midpoint */
  }

  100% {
    transform: scale(1);
    /* Back to original size */
    opacity: 0.6;
    /* Back to original opacity */
  }
}

.circular-menu.active .floating-btn {
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
}

.circular-menu .floating-btn:active {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
}

.circular-menu .floating-btn i {
  font-size: 1.3em;
  transition: transform 0.2s;
}

.circular-menu.active .floating-btn i {
  transform: rotate(-45deg);
}

.circular-menu:after {
  display: block;
  content: "";
  width: 2rem;
  height: 2rem;
  border-radius: 100%;
  position: absolute;
  top: 0rem;
  right: 0.2rem;
  z-index: -2;
  /* border: #FFB200 1px solid; */
  transition: all 0.3s ease;
  box-sizing: content-box;
  /* background: #000; */
}

.circular-menu.active::after {
  width: 7.5em;
  height: 7.5em;
  transform: scale(3);
  background-color: #000000;
  border: 1px solid #FFB200;
  transition-timing-function: cubic-bezier(0.68, 1.55, 0.265, 1);
}

.circular-menu .items-wrapper {
  padding: 0;
  margin: 0;
}

.circular-menu .menu-item {
  position: absolute;
  z-index: -1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: white;
  font-size: 1em;
  opacity: 0;
  border-radius: 100%;
  text-align: center;
  line-height: 3;
  border: #FFB200 3px solid;
  background: #111111;
  transition: transform 0.3s ease, background 0.2s ease;
}

.circular-menu.active .menu-item {
  opacity: 1;
  width: 3.5rem;
  height: 3.5rem;
}

.circular-menu.active .menu-item {
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.circular-menu.active .menu-item:nth-child(1) {
  transform: translate3d(-13.5em, -2em, 0);
}

.circular-menu.active .menu-item:nth-child(2) {
  transform: translate3d(-13em, 5em, 0);
}

.circular-menu.active .menu-item:nth-child(3) {
  transform: translate3d(-7.8em, 10.8em, 0);
}

.circular-menu.active .menu-item:nth-child(4) {
  transform: translate3d(0em, 11.7em, 0);
}

.nav-links-wrapper {
  position: absolute;
  left: 0;
  visibility: hidden;
}

.nav-links-wrapper img {
  margin-right: 5px;
}

.circular-menu.active .nav-links-wrapper {
  visibility: visible;
}

.nav-links-wrapper .nav-link-item {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  transform: translate3d(0, 0, 0);
  min-width: fit-content;
}

.nav-link-item a {
  color: #fff;
  text-decoration: none;
}

.circular-menu.active .nav-link-item {
  transition: all 0.3s ease;
}

.circular-menu.active .nav-link-item:nth-child(1) {
  transform: translate3d(-7em, -2em, 0);
}

.circular-menu.active .nav-link-item:nth-child(2) {
  transform: translate3d(-13.5em, 2.5em, 0) rotate(-35deg);
}

.circular-menu.active .nav-link-item:nth-child(3) {
  transform: translate3d(-18em, 5.3em, 0) rotate(-70deg);
}

@media (max-width: 600px) {
  .footer-sec .head-sec h4 {
    max-width: 100%;
  }

  .products .cards div.bottom img {
    height: 10rem;
    position: relative;
    bottom: -2rem;
  }

  .products .cards div img {
    height: 13rem;
  }

  .build-to-scale aside,
  .dedicated aside {
    width: 100%;
  }

  .unprecedented img.main {
    height: 15rem !important;
  }

  .unprecedented .cards div img {
    height: 5rem;
  }

  .unprecedented .cards div {
    width: 100%;
    padding: 3rem 2rem;
  }

  .hero-section .flex-between,
  .footer-sec .flex-between,
  footer {
    flex-direction: column;
  }

  .mobile {
    display: block;
  }

  .desktop {
    display: none;
  }

  .instant .flex-between {
    flex-direction: column-reverse !important;
  }

  .instant .flex-between .head-sec {
    margin-left: 0 !important;
  }

  .instant .head-sec p,
  .instant .head-sec h4 {
    max-width: 100% !important;
  }

  .instant {
    padding: 0 3rem !important;
    padding-top: 4rem !important;
  }

  .instant img {
    width: 120%;
  }

  .industry ul {
    grid-template-columns: auto;
    width: 100%;
  }

  .mobile-container {
    overflow: hidden;
    width: 100%;
  }

  .img-g4 {
    left: -48%;
    top: -5%;
  }

  .footer-adress-container {
    width: 100%;
  }

  .footer-address {
    width: 100%;
  }

  .footer-address p {
    font-size: 1.5rem;
    max-width: 100% !important;
  }

  .in-flex-center {
    font-size: 1.4rem !important;
  }

  .mobile-footer {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* height: 100%; */
  }

  .flex-between {
    justify-content: space-between;
    align-items: center;
  }

  .footer-logo img,
  .footer-social,
  .footer-address {
    margin-left: 0;
    margin-top: 1rem !important;
  }

  .footer-address p {
    margin-bottom: 4rem;
    line-height: 2.4rem;
  }

  .footer-logo img {
    width: 5rem;
  }

  .footer-social h4 {
    font-size: 1.6rem;
  }

  .footer {
    padding: 6rem 2rem 0rem 2rem!important;
    margin-top: 0;
    background-image: url(./assets/footer-background-mb.png);
    background-size: cover;
    height: 90vh !important;
    justify-content: flex-start;
  }

  .footer-links {
    display: none;
  }

  .social-icons a img {
    width: 2rem;
    margin-bottom: 1rem;
  }

  .title {
    font-size: 1.6rem;
  }

  .down-arrow-footer {
    width: 1.6rem;
  }

  .white-horizontal {
    margin: 2rem 0;
  }

  .sub-title {
    font-size: 1.5rem;
    margin: 2rem 0;
    opacity: 0.5;
  }

  .down-arrow {
    margin-top: -2.2rem;
  }
  .cards {
    margin-top: 4rem;
  }
  .verify {
    min-height: 60vh;
  }
}